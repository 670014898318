import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, TextField, Button, Select,MenuItem,FormControl,InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { PostData } from "../services/PostData";


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '400px',
        margin: 'auto',
        borderRadius: '5px',
        border: 'none',

    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0, 0, 0),
        width: '400px',
    },

    header: {
        "background": "#3f51b5", "color": "#fff", "padding": "12px 20px", "fontSize": "15px", "fontWeight": "600"
    },
    body: {
        padding: theme.spacing(4, 4, 3),
        fontSize: "16px",
        color: "#000",
        fontWeight: "400"
    },
    action: {
        padding: theme.spacing(2, 2, 4, 3),
        textAlign: "center"
    },
    btn: {
        marginRight: 16,

    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    listStyle:{
        height:180,
        overflow:'auto'
    }
}));
function RenewSubcription(props) {
    const { cusid } = props;
    const classes = useStyles();
    const [data, setdata] = useState({
        token: localStorage.getItem('token'),
        user_id: localStorage.getItem('user_id')
    })
    const [rows, setrows] = useState([])

    const [open, setOpen] = useState(true);
    const [subsc, setSubsc] = useState({
        ...cusid,
        subscription_plan_id:"",
        
    })
    const [success, setsuccess] = useState("")

    useEffect(() => {
        PostData('admin/subscriptionplan', data).then((result) => {
            let responseJSON = result;
            let sub = []
            if (responseJSON.status === "SUCCESS") {
                sub.push(responseJSON.data)
                console.log("dd", responseJSON.data);
                setrows(sub[0])
            }
            else {

            }
        })

    }, [])
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
        props.opens(false)
    };


    const handleChange = (event) => {
        setSubsc({
            ...cusid,
            subscription_plan_id:event.target.value,
            payment_mode: "cash",
            terms_condition: 1
        });

        console.log("asd", subsc);
        
    };
    const isRenewed = () => {
        PostData('admin/usersubscription', subsc).then((result) => {
            let responseJSON = result;
            if (responseJSON.status === "SUCCESS") {
                console.log("success", responseJSON);

                console.log("status", responseJSON.status);
                
                setsuccess("This user activated successfully.")
                handleClose()
            }
            else {
                setsuccess("This user NOT activated successfully.")
                console.log("status", responseJSON.status);
            }
        })
        console.log("subsc", subsc);
        
    }
    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            
        >
            <div className={classes.paper}>
                <div className={classes.header}>
                    Renewal user subscription
                   </div>
                <div className={classes.body}>
                    Choose the subscription pack from bellow.
                     <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Subscription Packs</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={subsc.id}
                            onChange={handleChange}
                            label="Subscription Packs"
                            fullWidth
                            
                        >
                            {rows.map((row) => (
                                <MenuItem value={row.id} key={row.id}  style={{textTransform:'capitalize'}}>
                                        <em> {row.subscription_type} | Rs.{row.charges} | {row.validity}Days</em>
                                    </MenuItem>
                            ))}
                           
                           
                        </Select>
                    </FormControl>
                   </div>
                <div className={classes.action}>
                    <Button variant="outlined" color="primary" className={classes.btn} onClick={isRenewed}> Renewe Subscription</Button>
                    <Button variant="outlined" color="secondary" onClick={handleClose}> Cancel</Button>
                </div>
            </div>

        </Modal>
    )
}

export default RenewSubcription
