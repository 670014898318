import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Router} from "react-router-dom";
import { Route, Switch, Redirect } from 'react-router-dom';
import SignIn from "./componants/sign-in/SignIn";
import Dashboard from "./componants/dashboard/Dashboard";
import { createBrowserHistory } from 'history'
 import Customers from "./componants/customers/Customers";
import Layout from "./componants/layout/Layout";
const customHistory = createBrowserHistory();

export default () => (
  <div></div>
  // <Router history={customHistory}>
  //   <Switch>
  //     <Route exact={true} path="/" component={SignIn} />
  //     <Route path="/dashboard" component={Layout}></Route>
  //     <Redirect from="/signin" to="/" />
  //   </Switch>
   
  // </Router>

  );
