import React, { useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { PostData } from "../services/PostData";
import { ToastContainer, toast } from 'react-toastify';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://sixcoretech.in/">
                Sixcore Technology
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height:45
    },
}));

function SignIn(props) {
    const classes = useStyles();
    const notifyError = () => toast.error("Please enter username/password", { autoClose: 3000 });
    const [data, setdata] = useState({
        username: "",
        password: "",
    })
    const onChange = (e) => {
        setdata({
            ...data,
            [e.target.name]: e.target.value
        })
        console.log(data);
    }
    const [error, seterror] = useState("")

   

    const login =()=>{
       if(data.username==="" || data.password===""){
           notifyError()
       }else{
           PostData('adminlogin', data).then((result) => {
               let responseJSON = result;
               let token;
               let user_id;
               if (responseJSON.status === "SUCCESS") {
                   token = responseJSON.token;
                   user_id = responseJSON.user_details.id;
                   //this.notify()
                  
               } else {

                   seterror(responseJSON.message)
                   //this.notify()
               }
               console.log(responseJSON);
               if (responseJSON.token) {
                   localStorage.setItem("token", token);
                   localStorage.setItem("user_id", user_id);
                   props.history.push('/dashboard');
                
               } else {

                   seterror(responseJSON.message)
               }
           })
       }
       
    }

    const handleKeyPress = event => {
        if (event.key == 'Enter') {
            // this.setState({ progress: true })
            login()
        }
    };

    useEffect(() => {
        if (localStorage.getItem("token")) {
            props.history.push('/dashboard')
            return;
        }
       
    },[])
 

    return (
        <Container component="main" maxWidth="xs">
           
            <CssBaseline />
            
            <div className={classes.paper}>
                
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
               
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="User Name"
                        name="username"
                        autoFocus
                        onChange={onChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        onChange={onChange}
                        onKeyPress={handleKeyPress} 
                    />
                <Typography  variant="body2" color="secondary" style={{ marginRight: 'auto'}}> {error}</Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={login}
                    >
                        Sign In
          </Button>
                    
            </div>
            <Box mt={4}>
                <Copyright />
            </Box>
            
        </Container>
    );
}

export default SignIn