import React, { useState, useEffect} from 'react'
import clsx from 'clsx';
// import { Switch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import Customers from '../customers/Customers';
import { HashRouter, Route, Link, Redirect, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history'
import Dashboard from '../dashboard/Dashboard';
import Subscription from '../subscription/Subscription';
import SignIn from '../sign-in/SignIn';
const customHistory = createBrowserHistory({
  queryKey: false,
});
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));
 const Layout =(props)=> {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [selectedIndex, setselectedIndex] = useState()
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const logout = () => {
        localStorage.setItem("token", '')
        localStorage.setItem("user_id", '')
        localStorage.clear();
        props.history.push('/')
    }
    
    useEffect(() => {
        var token = localStorage.getItem('token');
        if (token == null) {
            console.log("redirect to login");
            props.history.push('/')
        }
        var index= localStorage.getItem("index");
         setselectedIndex(index);
    }, [])


   const handleListItemClick = (event, index) => {
        localStorage.setItem('index', index)
        setselectedIndex(index)
        console.log("selectedIndex",selectedIndex);
    };


    const customerData = (data) => {
        console.log("count customer", data);

    }
        return (
          <div className={classes.root}>
            <CssBaseline />
            <HashRouter history={customHistory}>
              <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}
              >
                <Toolbar className={classes.toolbar}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(
                      classes.menuButton,
                      open && classes.menuButtonHidden
                    )}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    className={classes.title}
                  >
                    Dashboard
                  </Typography>
                  <IconButton color="inherit" onClick={logout}>
                    <PowerSettingsNewIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>

              <Drawer
                variant="permanent"
                classes={{
                  paper: clsx(
                    classes.drawerPaper,
                    !open && classes.drawerPaperClose
                  ),
                }}
                open={open}
              >
                <div className={classes.toolbarIcon}>
                  <IconButton onClick={handleDrawerClose}>
                    SOCIETY ADMIN <ChevronLeftIcon />
                  </IconButton>
                </div>
                <Divider />
                <List>
                  <ListItem
                    button
                    onClick={(event) => handleListItemClick(event, "dashboard")}
                    // selected={selectedIndex === "dashboard"}
                    component={Link}
                    to="/dashboard"
                  >
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItem>
                  <Divider />
                  <ListItem
                    button
                    component={Link}
                    to="/customers"
                    // selected={selectedIndex === "customers"}
                    onClick={(event) => handleListItemClick(event, "customers")}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Customers" />
                  </ListItem>
                  <Divider />
                  <ListItem
                    button
                    // selected={selectedIndex === "subscription"}
                    onClick={(event) =>
                      handleListItemClick(event, "subscription")
                    }
                    component={Link}
                    to="/subscription"
                  >
                    <ListItemIcon>
                      <ShoppingCartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Subscription Plan" />
                  </ListItem>
                  <Divider />
                </List>
              </Drawer>
              <div style={{ display: "none" }}>
                <Dashboard key={selectedIndex} history />
              </div>

              <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    {/* <Route path="/dashboard" component={Dashboard} /> */}
                    <Route exact path="/customers" component={Customers} />
                    <Route
                      exact
                      path="/subscription"
                      component={Subscription}
                    />
                    <Route path="*" component={Dashboard} />
                  </Switch>
                </Container>
              </main>
            </HashRouter>
          </div>
        );
    
}

export default Layout
