import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

import 'react-toastify/dist/ReactToastify.css';
import { Router } from "react-router-dom";
import { HashRouter,Route, Switch, Redirect } from "react-router-dom";
import SignIn from "./componants/sign-in/SignIn";
import Dashboard from "./componants/dashboard/Dashboard";
import { createBrowserHistory } from 'history'
import Customers from "./componants/customers/Customers";
import Layout from "./componants/layout/Layout";
const customHistory = createBrowserHistory();
const Root = () => {
  return (
    <HashRouter history={customHistory}>
      <Switch>
        <Route exact={true} path="/" component={SignIn} />
        <Route path="/" component={Layout} />
        <Route path="*" component={SignIn} />
        <Redirect from="/signin" to="/" />
      </Switch>
    </HashRouter>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();