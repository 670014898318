import React, { useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Customers from '../customers/Customers';
import { PostData } from '../services/PostData';
import { Card, CardContent } from '@material-ui/core';
import { Router, Route, Link, Redirect } from "react-router-dom";
import { createBrowserHistory } from 'history'
import signin from '../sign-in/SignIn';
const customHistory = createBrowserHistory();


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        // height: 250,
    },
    fixedHeight2: {
        height: 420,
    },
    centerText:{
        alignItems: 'center', 
        justifyContent: 'center'
    }
}));

function Dashboard(props) {
    const classes = useStyles();
    const [data, setdata] = useState({
        token: localStorage.getItem('token'),
        user_id: localStorage.getItem('user_id')
    })
    const [CustomerCount, setCustomerCount] = useState("")
    const [activeCust, setactiveCust] = useState("")
    const [subexp, setsubexp] = useState("")
    const [trial, settrial] = useState("")
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const fixedHeightPaper2 = clsx(classes.paper, classes.fixedHeight2);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            console.log("token", localStorage.getItem("token"));
            _getCustomerCount()
            //  props.history.push('/dashboard')
            return;
        }
        
    },[])

    // useEffect(() => {
    //     var token = localStorage.getItem('token');
    //     if (token == null) {
    //         console.log("redirect to login");
    //          props.history.push('/')
    //     }
    // }, [])

   const _getCustomerCount =()=>{
       console.log("hello");
       
       PostData('admin/userlist', data).then((result) => {
           let responseJSON = result;
           let cust = [];
           let ren =[];
           if (responseJSON.status === "SUCCESS") {
               cust.push(responseJSON.data)
               responseJSON.data.map((data) => (data.status == "1" ? cust.push(1) : ''))
               responseJSON.data.map((data) => (data.plan_remaining_days == 'expired' ? ren.push(1) : ''))
            //    console.log("ddasd", );
            //    console.log("ren",ren.length);
            const expired = result.data.filter(
              (item) => item.plan_remaining_days == "expired"
            );
            console.log(result.data)
            const trial=result.data.filter(
                (item)=>item.service_type== "trial"
               
            )
                settrial(trial.length)

               console.log('total count',result.data.length,'expired',expired.length,'trial user',trial.length)
               setsubexp((cust.length)-(cust.length-1))
               setactiveCust(cust.length-1)
               setCustomerCount(result.data.length)
             
           }
           else {

           }
       })
   }
    return (
        <Grid container spacing={3}>
                            
        <Grid item xs={12} md={12} lg={12}>
                <Paper className={fixedHeightPaper }>
                    
                        <Typography> Hello Admin,</Typography>
                        <Typography component="h6" variant="h6" color="inherit" noWrap >
                            Welcome to society Admin Portal
                    </Typography>  
                   
                    <Grid container >
                        <Grid item xs={12} md={4} lg={3} style={{ border: '#3f51b5', marginTop: '5%',borderWidth:1,borderStyle:'solid',marginRight:10,borderRadius:4}}>
                        <Card>
                            <CardContent>
                                <Typography style={{ fontSize: 40, color: '#3f51b5' }}>{CustomerCount} </Typography>
                                <Typography style={{fontSize:14}}> Registered Customers</Typography>

                            </CardContent>
                        </Card> 
                    </Grid>  
                        <Grid item xs={12} md={4} lg={3} style={{ border: '#3f51b5', borderWidth: 1, borderStyle: 'solid', marginTop: '5%', marginRight: 10, borderRadius: 4 }}>
                        <Card>
                            <CardContent>
                                <Typography style={{ fontSize: 40, color: 'green' }}>{subexp} </Typography>
                                <Typography style={{ fontSize: 14 }}> Active Customers</Typography>

                            </CardContent>
                        </Card>
                    </Grid>   
                        <Grid item xs={12} md={4} lg={3} style={{ border: '#3f51b5', borderWidth: 1, borderStyle: 'solid', marginTop: '5%' }}>
                            <Card>
                                <CardContent>
                                    <Typography style={{ fontSize: 40, color: 'red' }}>{activeCust} </Typography>
                                    <Typography style={{ fontSize: 14 }}> Subscription Expired</Typography>

                                </CardContent>
                            </Card>
                        </Grid>    

                        <Grid item xs={12} md={4} lg={3} style={{ border: '#3f51b5', borderWidth: 1, borderStyle: 'solid', marginTop: '5%', marginRight: 10, borderRadius: 4 }}>
                        <Card>
                            <CardContent>
                                <Typography style={{ fontSize: 40, color: 'green' }}>{trial} </Typography>
                                <Typography style={{ fontSize: 14 }}> Trial user</Typography>

                            </CardContent>
                        </Card>
                    </Grid>   
                    </Grid>              
                </Paper>
             </Grid>
                            
        {/* <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>
                       
             </Paper>
        </Grid> */}
                            
            <Grid item xs={12} className={fixedHeightPaper2}>
             
            {/* <Customers />    */}
        </Grid>
    </Grid>    
    );
}

export default Dashboard;