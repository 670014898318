import React, { useState, useEffect}from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, TextField, Button, CircularProgress,Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import { PostData } from "../services/PostData";
import { Spinner } from "react-bootstrap";


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '400px',
        margin: 'auto',
        borderRadius: '5px',
        border: 'none',
        
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0, 0, 0),
        width: '400px',
    },
   
    header:{
     "background": "#3f51b5", "color": "#fff", "padding": "12px 20px", "fontSize": "15px", "fontWeight": "600" 
    },
    body:{
        padding: theme.spacing(4, 4, 3),
        fontSize: "16px", 
        color: "#000", 
        fontWeight: "400"
    },
    action:{
        padding: theme.spacing(2,2, 4, 3),
        textAlign: "center"
    },
    btn:{
        marginRight: 16,
       
    }

}));
function ActivateCustomer(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [progress, setprogress] = useState(false)
    const { cusid } = props;
    const [success, setsuccess] = useState("")
    useEffect(() => {
        
        console.log("cusid", cusid);
        
    }, [])
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
        props.open(false)
    };

    const isActivated =()=>{
        setprogress(true)
        PostData('admin/activateuser', cusid).then((result) => {
            let responseJSON = result;
           
            if (responseJSON.status === "SUCCESS") {
                console.log("success", responseJSON);
                setprogress(false)
                setsuccess("This user activated successfully.")
                handleClose()
            }
            else {
                setsuccess("This user NOT activated successfully.")
            }
        })
    }
    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition

            >  
                <div className={classes.paper}> 
                   <div className={classes.header}>
                      Activate user
                   </div>
                   <div className={classes.body}>
                    Are you sure you have to activated this user ?
                   </div>
                   <div className={classes.action}>
                    
                    <Button variant="outlined" color="primary" className={classes.btn} onClick={isActivated}> 
                        <span> Activate </span>
                        <Fade
                            in={progress}
                            style={{
                                transitionDelay: progress ? '800ms' : '0ms',
                            }}
                            unmountOnExit
                        >
                        <CircularProgress  size={24} style={{marginLeft:5}} />
                        </Fade>
                    </Button>
                   
                    <Button variant="outlined" color="secondary" onClick={handleClose}> Cancel</Button>
                   </div>
                </div>
           
        </Modal>
    )
}

export default ActivateCustomer
