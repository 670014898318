import React, { useState,useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PostData } from "../services/PostData";
import Paper from '@material-ui/core/Paper';
import { Button, Grid, TextField, TablePagination, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import ActivateCustomer from './ActivateCustomer';
import RenewSubcription from './RenewSubcription';
import { Router, Route, Link, Redirect } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        margin: '0 8',
        minWidth: '100%',
        minHeight:30
    },
}));

export default function Customers(props) {
    const classes = useStyles();

    const [data, setdata] = useState({
        token:localStorage.getItem('token'),
        user_id:localStorage.getItem('user_id'),
        type:" "
    })
    const [rows, setrows] = useState([])
    const [isOpenModal, setisOpenModal] = useState(false)
    const [isOpenModalRenew, setisOpenModalRenew] = useState(false)

    const [active, setactive] = useState({})
    const [renewal, setrenewal] = useState({})
    const [name, setname] = useState('')

    const [statuscode, setstatuscode] = useState()

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        _fetchData()
    }, [data])


    // useEffect(() => {
    //     console.log("customers");
    //     var token = localStorage.getItem('token');
    //     if (token == null) {
    //         console.log("redirect to login");
    //         props.history.push('/')
    //     }
    // }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const _fetchData =() =>{
        PostData('admin/userlist', data).then((result) => {
            let responseJSON = result;
            let cust = [];
            if (responseJSON.status === "SUCCESS") {
                cust.push(responseJSON.data)
                setactive(responseJSON.data.admin_status)
                // console.log("dd", responseJSON.data);
                setrows(cust[0].reverse())
            }
            else {

            }

        })
    }

    const actived = (status, type = 1)=>{
        setisOpenModal(true)
        setactive({
            token: localStorage.getItem('token'),
            user_id: localStorage.getItem('user_id'),
            flag: type,
            activation_user_id: status
        })
        
    }
    const isRenewed = (status) => {
        setisOpenModalRenew(true)
        setrenewal({
            token: localStorage.getItem('token'),
            user_id: localStorage.getItem('user_id'),
            customer_id: status
        })

    }
    const handleClose = (val) => {
        _fetchData()
        setisOpenModal(val)
        // props.open=false
        //window.location.reload();
        console.log(" open", val);
    };
    const handleClose2 = (val) => {
        _fetchData()
        setisOpenModalRenew(val)
        // props.open=false
        //window.location.reload();
        console.log(" open", val);
    };
    const onChange = (e) => {

        // if (e.target.value == '1' || e.target.value == '0'){
        //     setstatuscode(e.target.value)
        // }
        setname(titleCase(e.target.value).toLowerCase())
        //setname((e.target.value).toLowerCase())
        console.log(data);
        _fetchData()
    }
    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    console.log("custome", rows);


    const handleChange = (event) => {
        setdata({...data,type: event.target.value})
        _fetchData()
    };

    return (
        <Paper className={classes.paper}>
        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Customers  active users 
            </Typography>
            <Grid container > 
                <Grid item xs={12} md={6} style={{ marginRight: 10, textAlign: "end" }}>
                    <TextField id="outlined-basic" label="Search by Bank Name" variant="outlined" size="small"  onChange={onChange} />
                </Grid>
                    <Grid item xs={12} md={4} style={{ marginRight: 10, }}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label" style={{marginTop:'-10px'}}>Filter Customer Data</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                onChange={handleChange}
                                label="Select Type"
                                size="small"
                                fullWidth
                            >
                                <MenuItem value=" " >
                                    <em> All Customers</em>
                                </MenuItem>
                                <MenuItem value="active" >
                                    <em> Active Customers</em>
                                </MenuItem>
                                <MenuItem value="deactive">
                                    <em> Deactive Customers</em>
                                </MenuItem>
                                <MenuItem value="expired">
                                    <em> Expired Customers</em>
                                </MenuItem>
                            </Select>
                        </FormControl>
                </Grid>
                </Grid>
                <br />
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Bank Name</TableCell>
                        {/* <TableCell>Email</TableCell> */}
                        <TableCell>Service Type</TableCell>
                        <TableCell>Remaining Days</TableCell>
                        <TableCell >Status</TableCell>
                         <TableCell >Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                        {rows.filter(person => person.bank_society_name.toLowerCase().includes(name.toLowerCase()) ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.full_name}</TableCell>
                            <TableCell>{row.bank_society_name}</TableCell>
                            {/* <TableCell>{row.email}</TableCell> */}
                            <TableCell>{row.service_type}</TableCell>
                            <TableCell>{row.plan_remaining_days}</TableCell>
                            <TableCell>{row.status == '1' ? "Active" : "Deactivate"}</TableCell>
                            <TableCell>  
                                {
                                    row.status == '1' ?
                                        <Button variant="contained" color="secondary" onClick={() => actived(row.id, 2)}> Renewal </Button>
                                        :
                                        <Button variant="contained" color="primary"  onClick={() => actived(row.id)}> Activate </Button>
                                        
                                }
                             
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
             <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
          <Grid>
                    {isOpenModal && <ActivateCustomer cusid={active} open={handleClose}/>}
                    {isOpenModalRenew && <RenewSubcription cusid={renewal} opens={handleClose2} />}

          </Grid>
        </React.Fragment>
      </Paper>
    );
}