import React, { useState, useEffect } from 'react';
import {Typography,Paper,Grid,Button,TextField,Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PostData } from "../services/PostData";
import { ToastContainer, toast } from 'react-toastify';
import { Router, Route, Link, Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '25ch',
    },

}));
function Subscription() {
    const classes = useStyles();
    const [data, setdata] = useState({
        token: localStorage.getItem('token'),
        user_id: localStorage.getItem('user_id')
    })

    const onChange = (e) => {
        setdata({
            ...data,
            [e.target.name]: e.target.value
        })
        console.log(data);
    }
    const notifyError = () => toast.error("Please fill details properly", { autoClose: 3000 });

    const [rows, setrows] = useState([])
    useEffect(() => {
        _fetchData()
    }, [])

    const _fetchData=()=>{
        PostData('admin/subscriptionplan', data).then((result) => {
            let responseJSON = result;
            let sub = []
            if (responseJSON.status === "SUCCESS") {
                sub.push(responseJSON.data)
                console.log("dd", responseJSON.data);
                setrows(sub[0])
            }
            else {

            }
        })

    }
    const addSubscription =()=>{
       
        if (!data.subscription_type || !data.charges || !data.validity || !data.description) {
            notifyError()
        } else {
        PostData('admin/addsubscription', data).then((result) => {
            let responseJSON = result;
            if (responseJSON.status === "SUCCESS") {
               console.log("record added");
                _fetchData()
               setdata({
                 subscription_type: "",
                 charges: "",
                 validity: "",
                 description: "",
                 token: localStorage.getItem("token"),
                 user_id: localStorage.getItem("user_id"),
               });
            }
            else {

            }
        })
        }
    }

    return (
        <Paper className={classes.paper}>
            
            <Grid container spacing={3}>
            
                <Grid item xs={12} md={8} lg={9}>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    />
                    <Typography component="h6" variant="h6" color="primary" noWrap >
                        Subscription Plan
                    </Typography>
                </Grid>
                {/* <Grid item xs={12} md={4} lg={3}>
                    <Button variant="outlined" color="secondary"> Add Subscription</Button>
                </Grid> */}
                <Grid item xs={12}>
                    <Grid item xs={12} md={9} >
                        <TextField id="outlined-basic" className={classes.textField} label="Subscription Name" variant="outlined" size="small" name="subscription_type" value={data.subscription_type}  onChange={onChange} />
                        <TextField id="outlined-basic" className={classes.textField} type="number" label="Charges (₹)" variant="outlined" size="small" name="charges" value={data.charges} onChange={onChange}/>
                        <TextField id="outlined-basic" className={classes.textField} type="number" label="Validity in days" variant="outlined" size="small" name="validity" value={data.validity} onChange={onChange}/>
                    </Grid>
                    <Grid item xs={12} md={9} >
                        <TextField id="outlined-basic" className={classes.textField} label="Description" variant="outlined" size="small" style={{ width: '50%' }} name="description" value={data.description}  onChange={onChange}/>
                        <Button variant="contained" color="primary" className={classes.textField} onClick={addSubscription}> Add Subscription</Button>
                    </Grid>
                    <br/>
                    <Grid item xs={12} >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Subscription Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Charges</TableCell>
                                    <TableCell>Validity in Days</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.subscription_type}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>{row.charges}</TableCell>
                                        <TableCell>{row.validity}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
            </Grid>
            </Grid>
        </Paper>
    )
}

export default Subscription
